.content-fourth {
  margin-left: 10vw;
  font-size: 0.1em;
}

.content-fourth .content {
  height: 300px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffd632;
  border-radius: 10px;
  border: 2px solid #650ba4;
  margin-right: 10vw; /* Ajuste da margem direita */
}
.content-fourth .content .text-one {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #650ba4;
  font-size: 20px;
  text-align: left;
}

.content-fourth .content .text-two {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 2px;
  color: #650ba4;
}

.content-fourth .content .text-three {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  text-align: left;
  margin-left: 2vw;
  color: #650ba4;
}

.content-fourth .content .text-four {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 2px;
  color: #650ba4;
}

.content-fourth .content .text-five {
  margin-left: 2vw;
  color: #650ba4;
  width: 75.5vw;
  display: flex;
  flex-direction: row;
}
.content-fourth .content .text-five .texts {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  text-align: left;
  width: 45vw;
  margin-left: 0;
}
.teste {
  position: absolute;
  height: 169px;
  width: 40vw;
  margin-top: 122px;
  margin-left: 37.22vw;
  border-bottom-right-radius: 10px;
}
@media screen and (min-width: 571px) {
  .teste {
    margin-left: 37.4vw;
  }
}
@media screen and (min-width: 700px) {
  .teste {
    position: absolute;
    height: 300px;
    width: 40vw;
    margin-top: 176px;
    margin-left: 37.48vw;
    border-bottom-right-radius: 30px;
  }
  .content-fourth .content {
    height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 30px;
    margin-right: 10vw; /* Ajuste da margem direita */
  }
  .content-fourth .content .text-one {
    font-size: 4vw;
  }

  .content-fourth .content .text-two {
    font-size: 20px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 4px;
  }

  .content-fourth .content .text-three {
    font-size: 20px;
  }

  .content-fourth .content .text-four {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  .content-fourth .content .text-five {
    width: 75vw;
    display: flex;
    flex-direction: row;
  }

  .content-fourth .content .text-five .texts {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
  }
}
@media screen and (min-width: 865px) {
  .teste {
    margin-left: 37.6vw;
  }
}

@media screen and (min-width: 1125px) {
  .teste {
    margin-left: 37.65vw;
  }
}
@media screen and (min-width: 1150px) {
  .teste {
    height: 400px;
    margin-top: 76px;
  }
}
@media screen and (min-width: 1300px) {
  .teste {
    margin-left: 37.7vw;
  }
}
@media screen and (min-width: 1501px) {
  .teste {
    margin-left: 37.75vw;
  }
}
@media screen and (min-width: 1801px) {
  .teste {
    margin-left: 37.81vw;
  }
}
@media screen and (min-width: 2400px) {
  .teste {
    margin-left: 37.85vw;
  }
}
