* {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.3em;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right, white, #650BA4); */
  background-color: beige;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button:hover {
  transform: scale(1.05);
  transition: 0.2s;
}
