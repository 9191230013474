.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #ffd632;
  color: #650ba4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}

.modal1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #ffd632;
  color: #650ba4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);font-weight: 800;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 15px;
}

.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
#whatsAppFixed {
  display: none;
}
#whatsApp {
  display: flex;
  width: 150px;
  margin-top: 10px;
  right: 0;
  align-items: right;
  justify-content: right;
}
#whatsApp:hover {
  cursor: pointer;
}
.modal-overlay {
  display: none;
}

.modal-content {
  display: none;
}

.modal-header {
  display: none;
}

.modal-body {
  display: none;
}
#home {
  font-size: 0.1em;
  background: linear-gradient(to left, white, #e380ff);
}
#home .content-one {
  margin-bottom: 30px;
}
#home .content-one .form-group #hashtag {
  width: 80vw;
  margin-top: 40px;
}

#home .content-one .form-group #gusAndBia {
  width: 90vw;
  align-items: right;
  margin-right: -10vw;
  margin-top: 20px;
  margin-bottom: 10px;
}

#home .content-one .form-group button {
  color: #650ba4;
  background-color: #ffd632;
  border: 1px solid #650ba4;
  width: 70vw;
  font-size: 3.5vw;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 800;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}
#home .content-one .form-group .yellow-down {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #ffd632;
  width: 100vw;
  font-size: 0.8em;
  padding-top: 10px;
  padding-bottom: 10px;
}
#home .content-one .form-group .yellow-down div {
  width: 25vw;
  color: #650ba4;
  font-family: "Questrial", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/*Start Content-two mobile */

#home #purple-rectangle {
  background-color: #650ba4;
  height: 17px;
}

#home #purple-rectangle2 {
  background-color: #650ba4;
  height: 17px;
  margin-top: -3px;
}

#home #image-yellow-content-two {
  width: 100vw;
  height: 800px;
  margin-left: 0;
}

#home .content-two .before-column-desktop {
  display: none;
}

#home .content-two .column-one .text-one {
  margin-top: -750px;
  font-size: 32.6px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 800;
  color: #650ba4;
}

#home .content-two .column-one .text-two {
  font-family: "Questrial", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 85px;
  color: #650ba4;
  margin-top: -10px;
}

#home .content-two .column-one .text-three {
  font-family: "Questrial", sans-serif;
  color: #650ba4;
  width: 76vw;
  margin-left: 12vw;
  font-size: 16px;
}
#home .content-two .column-one .text-four {
  display: none;
}

#home .content-two .column-two .image-one {
  margin-top: 30px;
  width: 60vw;
  height: 100px;
  margin-bottom: 30px;
}

#home .content-two .column-two-two {
  display: none;
}
#home .content-two .column-three form div select option {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

#home .content-two .column-three form div select,
#home .content-two .column-three form div input {
  font-family: "Montserrat", sans-serif;
  width: 60vw;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #650ba4; /* Definindo a borda */
  font-size: 15px;
  background-color: #f4dd83;
  color: #650ba4;
}

#home .content-two .column-three form div select::placeholder,
#home .content-two .column-three form div input::placeholder {
  font-family: "Montserrat", sans-serif;
  color: #650ba4;
}

#home .content-two .column-three form button {
  width: 25vw;
  color: #ffd632;
  background-color: #650ba4;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin-top: 10px;
  border-radius: 8px;
  font-weight: 700;
  border: none;
}

#home .content-two .column-three .text-one {
  font-family: "Montserrat", sans-serif;
  color: #650ba4;
  width: 60vw;
  margin-left: 20vw;
  margin-top: 5vh;
  font-size: 13px;
  font-weight: 800;
}

/*End Content-two mobile */

/*Start Content-three mobile */

#home .content-three .column-one .image-one {
  width: 67vw;
  align-items: left;
  image-orientation: left;
  margin-left: -30vw;
  margin-top: 150px;
}

#home .content-three .column-two div {
  margin-top: -100px;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-weight: 800;
  color: #650ba4;
  font-size: 1.5em;
  margin-right: 5vw;
  margin-left: 50vw;
}

#home .content-three .column-two button {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-right: 10vw;
  margin-left: 50vw;
  font-size: 0.8em;
  border: 1px solid #650ba4; /* Definindo a borda */
  background-color: #ffd632;
  border-radius: 8px;
  margin-top: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #650ba4;
}
/*End Content-three mobile */

/*Start Content-foury mobile */
#home .content-foury {
  margin-top: 80px;
}
#home .content-foury .text-one-y {
  font-family: "Questrial", sans-serif;
  font-size: 3em;
  color: #650ba4;
}

#home .content-foury .text-two-y {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-top: -10px;
  font-size: 5em;
  color: #650ba4;
}

#home .content-foury button {
  background-color: #ffd632;
  margin-top: 10px;
  border-radius: 4px;
  border: 2px solid #650ba4;
  padding-right: 6px;
  padding-left: 6px;
  color: #650ba4;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

/*End Content-foury mobile */

/*Start Content-five mobile */
#home .content-five {
  margin-top: 50px;
}

#home .content-five .form-group-two-two {
  display: none;
}

#home .content-five .form-group-two .flex-one {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  color: #650ba4;
}

#home .content-five .form-group-two .flex-one .text-one,
#home .content-five .form-group-two .flex-one .text-two {
  padding-top: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 1000;
}

#home .content-five .form-group-two .flex-one .purple-vertical {
  width: 5px;
  background-color: #650ba4;
  height: 50px;
}
#home .content-five .form-group-two .image {
  width: 100vw;
  margin-top: 10px;
}

#home .content-five .form-group-one .text-one {
  font-family: "Questrial", sans-serif;
  font-size: 3em;
  color: #650ba4;
}

#home .content-five .form-group-one .text-two {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2.3em;
  color: #650ba4;
}

#home .content-five .form-group-three {
  display: none;
}

#home .content-five .form-group-four {
  margin-top: 30px;
}

#home .content-five .form-group-four .text-one {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.5em;
  color: #650ba4;
  margin-bottom: 5px;
}

#home .content-five .form-group-four .columns-one {
  display: flex;
}

#home .content-five .form-group-four .columns-one .left-side input {
  width: 50vw;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #650ba4; /* Definindo a borda */
  font-size: 15px;
  background-color: #f4dd83;
  color: #650ba4;
  font-family: "Montserrat", sans-serif;
}

#home
  .content-five
  .form-group-four
  .columns-one
  .left-side
  input::placeholder {
  font-family: "Questrial", sans-serif;
  color: #650ba4;
}

#home .content-five .form-group-four .columns-one .right-side input {
  font-family: "Montserrat", sans-serif;
  border-radius: 120px;
  height: 80px;
  width: 25vw;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 3vw;
  text-align: center;
  background-color: #ffd632;
  border: 1px solid #650ba4;
}
#home
  .content-five
  .form-group-four
  .columns-one
  .right-side
  input::placeholder {
  font-family: "Questrial", sans-serif;
  color: #650ba4;
}

#home .content-five .form-group-four .columns-one .right-side button {
  background-color: #ffd632;
  font-size: 1em;
  border: 1px solid #650ba4;
  border-radius: 5px;
  margin-right: 3vw;
  color: #650ba4;
  padding: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
/*End Content-five mobile */

/*Start Content-six mobile */

#home .content-six .text-one {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 3em;
  color: #650ba4;
  margin-bottom: 10px;
  margin-top: 50px;
}

#home .content-six .text-two {
  font-family: "Questrial", sans-serif;
  color: #650ba4;
  width: 80vw;
  text-align: start;
  margin-left: 10vw;
  font-size: 13px;
  padding-bottom: 60px;
}

/*End Content-six mobile */

/* Starting desktop version */

@media screen and (min-width: 700px) {
  #whatsAppFixed {
    display: flex;
    position: fixed;
    align-items: right;
    justify-content: right;
    right: 0;
    width: 280px;
    margin-top: 450px;
    z-index: 5000;
  }
  #whatsAppFixed:hover {
    cursor: pointer;
  }
  #whatsApp {
    display: none;
  }
  .modal-overlay {
    display: block;
    position: fixed;
    top: 420px;
    right: 20px;
    bottom: 30px;
    display: flex;
    justify-content: right;
    align-items: right;
    z-index: 1000;
    width: 100vw;
  }

  .modal-content {
    display: block;
    background: #e380ff;
    padding: 20px;
    border-radius: 8px;
    width: 250px;
    height: 140px;
  }

  .modal-header {
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .modal-body {
    display: block;
    font-size: 11px;
    text-align: justify;
    margin-top: -15px;
  }

  .modal-body button {
    font-size: 11px;
    background-color: #ffd632;
    border: none;
    font-family: "Montserrat", sans-serif;
    color: #650ba4;
    font-weight: 800;
    margin-top: 5px;
    border: 1px solid #650ba4;
    border-radius: 7px;
  }
  #home .content-one {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 100px;
  }
  #home .content-one .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #home .content-one .form-group #hashtag {
    width: 50vw;
    margin-left: 5vw;
    margin-top: -50px;
  }

  #home .content-one .form-group #gusAndBia {
    width: 36vw;
    align-items: right;
    margin-right: 0;
  }
  #home .content-one .form-group button {
    width: 48vw;
    font-size: 2vw;
    margin-left: 5vw;
  }
  #home .content-one .form-group .yellow-down {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #ffd632;
    width: 100vw;
    font-size: 1.6vw;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /*Start content-two desktop */
  #home .content-two .before-column {
    display: none;
  }

  #home .content-two .before-column-desktop {
    display: block;
  }

  #home #image-yellow-two-content-two {
    width: 100vw;
    height: 700px;
    margin-left: 0;
  }

  #home .content-two .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #home .content-two .columns .column-three {
    width: 30vw;
    margin-top: -690px;
  }

  #home .content-two .columns .column-one {
    width: 30vw;
    margin-top: -690px;
  }

  #home .content-two .column-one .text-one {
    margin-top: -30px;
    font-size: 3.5vw;
  }

  #home .content-two .column-one .text-two {
    font-size: 9vw;
  }

  #home .content-two .column-one .text-three {
    font-size: 2vw;
    text-align: left;
    margin-left: 1vw;
    width: 28vw;
  }
  #home .content-two .column-one .text-four {
    font-family: "Montserrat", sans-serif;
    width: 28vw;
    display: block;
    color: #650ba4;
    margin-top: 50px;
    font-weight: 800;
    margin-left: 1vw;
    text-align: left;
    font-size: 2vw;
  }

  #home .content-two .column-two {
    display: none;
  }

  #home .content-two .column-two-two {
    display: block;
    width: 10vw;
    margin-top: -690px;
  }

  #home .content-two .column-two-two .image-two {
    width: 10vw;
  }

  #home .content-two .column-three form div select,
  #home .content-two .column-three form div input {
    width: 30vw;
    font-size: 1.5vw;
  }

  #home .content-two .column-three form div select::placeholder,
  #home .content-two .column-three form div input::placeholder {
    font-family: "Questrial", sans-serif;
    color: #650ba4;
  }

  #home .content-two .column-three form button {
    width: 10vw;
  }

  #home .content-two .column-three .text-one {
    display: none;
  }

  /*End content-two desktop */

  /*Start content-three desktop */

  #home .content-three .column-one .image-one {
    width: 45vw;
    align-items: left;
    image-orientation: left;
    margin-left: -30vw;
    margin-top: 80px;
  }

  #home .content-three .column-two div {
    margin-top: -300px;
    font-size: 4.2vw;
    margin-right: 5vw;
    margin-left: 55vw;
    width: 40vw;
  }

  #home .content-three .column-two button {
    margin-right: -5vw;
    margin-left: 40vw;
    font-size: 2vw;
    border: 1px solid #650ba4; /* Definindo a borda */
    background-color: #ffd632;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px;
    color: #650ba4;
    width: 35vw;
  }
  /*End content-three desktop */

  /*Start content-foury desktop */
  #home .content-foury {
    margin-top: 180px;
  }
  #home .content-foury .text-one-y {
    font-size: 5em;
  }

  #home .content-foury .text-two-y {
    font-size: 8em;
  }

  #home .content-foury button {
    margin-top: 10px;
    padding: 6px;
    font-size: 1.6vw;
  }
  /*End content-foury desktop */

  /*Start Content-five desktop */
  #home .content-five {
    margin-top: 100px;
  }

  #home .content-five .form-group-two {
    display: none;
  }

  /* #home .content-five .form-group-two .flex-one {
    font-family: "Questrial", sans-serif;
    font-size: 0.85em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    color: #650ba4;
  }

  #home .content-five .form-group-two .flex-one .text-one,
  #home .content-five .form-group-two .flex-one .text-two {
    padding-top: 8px;
  }

  #home .content-five .form-group-two .flex-one .purple-vertical {
    width: 5px;
    background-color: #650ba4;
    height: 50px;
  }
  #home .content-five .form-group-two .image {
    width: 85vw;
    margin-top: 10px;
  } */

  #home .content-five .form-group-one .text-one {
    font-size: 5vw;
  }

  #home .content-five .form-group-one .text-two {
    font-size: 5vw;
  }

  #home .content-five .form-group-two-two {
    display: flex;
  }

  #home .content-five .form-group-two-two .flex-one {
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: #650ba4;
    gap: 3vw;
  }

  #home .content-five .form-group-two-two .flex-one .texts {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 8px;
    font-size: 1.7vw;
    margin-left: 5vw;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
  }

  #home .content-five .form-group-two-two .flex-one .purple-vertical {
    width: 5px;
    background-color: #650ba4;
    height: 100%;
  }
  #home .content-five .form-group-two-two .image {
    width: 55vw;
    margin-top: -30px;
    height: 300px;
    margin-right: 30px;
  }

  #home .content-five .form-group-three {
    display: block;
    margin-top: 60px;
    width: 100vw;
  }
  #home .content-five .form-group-four {
    display: none;
  }

  #home .content-five .form-group-three .text-one {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2vw;
    color: #650ba4;
    margin-bottom: 15px;
    text-align: left;
    margin-left: 7vw;
  }

  #home .content-five .form-group-three .columns-one {
    display: flex;
    justify-content: center;
    gap: 3vw;
  }

  #home .content-five .form-group-three .columns-one .left-side,
  #home .content-five .form-group-three .columns-one .middle-side {
    width: 35vw;
  }
  #home .content-five .form-group-three .columns-one .right-side {
    width: 10vw;
  }

  #home .content-five .form-group-three .columns-one .left-side input,
  #home .content-five .form-group-three .columns-one .middle-side input {
    width: 35vw;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #650ba4; /* Definindo a borda */
    font-size: 15px;
    background-color: #f4dd83;
    color: #650ba4;
    font-family: "Montserrat", sans-serif;
  }

  #home
    .content-five
    .form-group-three
    .columns-one
    .left-side
    input::placeholder,
  #home
    .content-five
    .form-group-three
    .columns-one
    .middle-side
    input::placeholder {
    font-family: "Questrial", sans-serif;
    color: #650ba4;
  }

  #home .content-five .form-group-three .columns-one .right-side input {
    font-family: "Montserrat", sans-serif;
    border-radius: 120px;
    height: 30px;
    width: 10vw;
    margin-top: -3px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #ffd632;
    border: 1px solid #650ba4;
  }
  #home
    .content-five
    .form-group-three
    .columns-one
    .right-side
    input::placeholder {
    font-family: "Questrial", sans-serif;
    color: #650ba4;
  }

  #home .content-five .form-group-three .columns-one .right-side button {
    background-color: #ffd632;
    font-size: 1em;
    border: 1px solid #650ba4;
    border-radius: 8px;
    color: #650ba4;
    padding: 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
  }
  /*End Content-five desktop */

  /*Start Content-six desktop */
  #home .content-six .text-one {
    font-size: 5vw;
    color: #650ba4;
    margin-bottom: 10px;
    margin-top: 150px;
  }

  #home .content-six .text-two {
    font-size: 2vw;
    padding-bottom: 60px;
  }
  /*End Content-six desktop */
}

@media screen and (min-width: 1000px) {
  #home .content-three .column-two div {
    margin-top: -400px;
  }
}

/* Versão Mobile First */
#nav {
  width: 100vw;
  display: flex !important;
  z-index: 1000 !important;

  justify-content: space-between !important;
  align-items: center !important;
  text-align: center;
  background-color: #650ba4 !important;
  height: 60px !important;
}

#nav .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 0.1em;
}
#nav .right #hamburger {
  font-size: 6em;
  color: #ffd632;
  margin-right: 3vw;
}
#nav .right #span {
  display: none;
}

#nav .right #span {
  color: #ffd632;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
  background-color: #650ba4;
  border: none;
  cursor: pointer;
}
#image {
  margin-left: 10px;
  height: 50px;
  justify-content: center;
}

#sideNavbar {
  display: flex;
  position: absolute;
  font-size: 3vw;
  top: 60px;
  width: 65vw;
  height: 190px;
  background-color: #650ba4;
  border-bottom-left-radius: 20px;
  opacity: 80%;
  padding-top: 10px;
  flex-direction: column;
  z-index: 2;
  padding-bottom: 15%;
}
#sideNavbar div div {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
  align-items: right;
}
#sideNavbar button {
  color: #ffd632;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none;
  background: none;
  text-align: right;
  padding-right: 2vw;
}
#sideNavbar.active {
  right: 0; /* Move para a posição inicial quando acionado */
}
#sideNavbar.notActive {
  display: none;
}
@media screen and (min-width: 420px) {
  #sideNavbar {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 700px) {
  #nav .right {
    display: flex;
    justify-content: space-evenly;
    gap: 2vw;
    margin-right: 5vw;
  }
  #nav .right #span {
    display: block;
    font-size: 1.6vw;
  }
  #nav .right #hamburger {
    display: none;
  }
  #sideNavbar {
    display: none;
  }
}
